<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="$attrs.size ? $attrs.size : '24'"
    viewBox="0 0 24 24"
  >
    <g
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g :stroke="$attrs.color ? $attrs.color : '#F29332'" stroke-width="1.5">
        <g>
          <g>
            <path
              d="M4 1.5H2c-1.105 0-2 .895-2 2v14c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-1"
              transform="translate(-483.000000, -309.000000) translate(483.000000, 309.000000) translate(3.000000, 2.000000)"
            />
            <path
              d="M10 15.5l3.15-.389c.221-.027.427-.128.585-.285l4.631-4.631c.845-.845.845-2.215 0-3.061-.845-.845-2.215-.845-3.061 0l-4.56 4.56c-.153.153-.252.351-.283.566L10 15.5zM10.5 3h-5C4.672 3 4 2.328 4 1.5h0C4 .672 4.672 0 5.5 0h5c.828 0 1.5.672 1.5 1.5h0c0 .828-.672 1.5-1.5 1.5zM4 6.5L10 6.5M4 10.5L8 10.5M12 1.5h2c1.105 0 2 .895 2 2"
              transform="translate(-483.000000, -309.000000) translate(483.000000, 309.000000) translate(3.000000, 2.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconEdit",
};
</script>

<style></style>
